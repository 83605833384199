export default interface CompanyStatus {
  id: number;
  name: string;
  description: string;
  color?: string;
  possibleStatus?: CompanyStatus[] | null;
}

export const statusList: CompanyStatus[] = [
  {
    id: 1,
    name: "PENDENTE_ASSINATURA",
    description: "Pendente de assinatura",
    color: "orange lighten-1"
  },
  {
    id: 2,
    name: "RECUSADA_ASSINATURA",
    description: "Assinatura recusada",
    color: "red lighten-3"
  },
  {
    id: 3,
    name: "PENDENTE_APR_MANUAL",
    description: "Pendente de aprovação manual",
    color: "blue"
  },
  {
    id: 4,
    name: "RECUSADA_APR_MANUAL",
    description: "Aprovação manual recusada",
    color: "red lighten-2"
  },
  {
    id: 5,
    name: "PENDENTE_VALID_DOCS",
    description: "Validação de documentos pendente",
    color: "orange darken-2"
  },
  {
    id: 6,
    name: "RECUSADA_VALID_DOCS",
    description: "Validação de documentos recusada",
    color: "red lighten-1"
  },
  { id: 7, name: "ATIVA", description: "Ativa", color: "green" },
  { id: 8, name: "INATIVA", description: "Inativa", color: "purple" },
  {
    id: 9,
    name: "INATIVA_GRUPO",
    description: "Inativa grupo",
    color: "purple lighten-3"
  }
].sort((a, b) => a.description.localeCompare(b.description));

export function statusDescription(statusName: string): string {
  const status = statusList.find((status) => status.name === statusName);
  return status?.description || statusName;
}

export function statusColor(statusName: string): string {
  const status = statusList.find((status) => status.name === statusName);
  return status?.color || "";
}
