
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import User from "@/types/user";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import SafetyService from "@/services/safety-service";
import CompanyService from "@/services/company-service";
import { ValidationProvider } from "vee-validate";
import Company from "@/types/company";
import PartnerService from "@/services/partner-service";
import Partner from "@/types/partner";
import { partnerStatusList } from "@/types/partner-status";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import { statusList } from "@/types/company-status";

@Component({
  components: { ValidationObserver, ValidationProvider, Modal },
  directives: { mask }
})
export default class UserRelations extends Vue {
  formatDocument = formatDocument;
  service: SafetyService;
  companyService: CompanyService;
  partnerService: PartnerService;

  loading = true;

  userCompanies: Company[] = [];
  userPartner: Partner | null = null;
  @Prop() readonly user!: User;

  constructor() {
    super();
    this.service = SafetyService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
  }

  async mounted(): Promise<void> {
    if (this.user.type === "EMPLOYEE") {
      await this.fetchUserCompanies();
    }

    if (this.user.type === "ADMIN_GROUP") {
      await this.fetchUserCompanies();
    }

    if (this.user.type === "PARTNER_MASTER") {
      await this.fetchUserPartnerByUserId();
    }

    if (this.user.type === "PARTNER") {
      await this.fetchUserPartner();
    }
  }

  async fetchUserPartner(): Promise<void> {
    if (!this.user || !this.user.id) {
      return;
    }
    const [error, partnerId] = await this.partnerService.getUserPartner(
      this.user.id
    );

    if (!error && partnerId && partnerId.partner) {
      this.userPartner = partnerId.partner;
    }
  }

  async fetchUserPartnerByUserId(): Promise<void> {
    if (!this.user || !this.user.id) {
      return;
    }
    const [error, partner] = await this.partnerService.getPartnerByUserId(
      this.user.id
    );

    if (!error && partner) {
      this.userPartner = partner;
    }
  }

  async fetchUserCompanies(): Promise<void> {
    const [error, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: 1000000,
      userId: this.user.id
    });
    if (!error) {
      this.userCompanies = companies?.items || [];
    }
  }

  partnerStatus(statusId: number): string {
    const status = partnerStatusList.find((status) => status.id === statusId);
    return status ? status.description : "";
  }

  companyStatus(statusId: number): string {
    const status = statusList.find((status) => status.id === statusId);
    return status ? status.description : "";
  }
}
