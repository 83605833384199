import Partner from "./partner";
import PartnerStatusHistory from "./partner-status-history";

export default interface PartnerStatus {
  id: number;
  name: string;
  description: string;
  partners?: Partner[];
  statusHistory: PartnerStatusHistory[];
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export const partnerStatusList = [
  {
    id: 2,
    name: "ATIVO",
    description: "Ativo"
  },
  {
    id: 3,
    name: "RECUSADO",
    description: "Recusado"
  },
  {
    id: 4,
    name: "INATIVO",
    description: "Inativo"
  }
];
