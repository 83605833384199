import Company from "./company";
import Profile from "./profile";
import UserBlacklistedCommunication from "./user-blacklisted-communication";

export default interface User {
  id?: number;
  name: string;
  email: string;
  phone: string;
  status: number;
  flag: number;
  type: string;
  companies?: Company[];
  profiles?: Profile[];
  blacklistedCommunications: UserBlacklistedCommunication[];
  index?: number | null;
}

export enum UserStatus {
  /** User requires approval AND has not been approved yet */
  PENDING_APPROVAL = 4, //
  /** User requires approval AND has been reproved */
  REPROVED = 5, //
  /** [User is already approved OR User does not require approval] AND has not confirmed signup yet */
  APPROVED = 0,
  /** [User is already approved OR User does not require approval] AND has already confirmed signup */
  ACTIVE = 1,
  /** User has been deactivated */
  INACTIVE = 2,
  /** User has been deleted manually */
  MANUAL_DELETED = 3
}

export enum UserFlag {
  REGULAR = 0, // pode operar livremente
  LIMITED = 1 // requer aprovação para operar livremente
}
