export default interface Communication {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
}

const endorsement: Partial<Communication> = {};
endorsement.id = 1;
endorsement.name = "AVERBACAO";
endorsement.description = "Averbação";

const reconciliationOrCharges: Partial<Communication> = {};
reconciliationOrCharges.id = 2;
reconciliationOrCharges.name = "CONCILIACAO_OU_COBRANCA";
reconciliationOrCharges.description = "Conciliação/Cobrança";

export const CommunicationEnum = {
  ENDORSEMENT: endorsement,
  RECONCILIATION_OR_CHARGES: reconciliationOrCharges,
};
