import { format as formatCPF } from "@/utils/cpf";
import { format as formatCNPJ } from "@/utils/cnpj";

export function format(document: string) {
  if (document.length <= 11) {
      document = formatCPF(document);
  } else {
      document = formatCNPJ(document);
  }
  return document;
}
