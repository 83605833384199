
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import User, { UserStatus, UserFlag } from "@/types/user";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import SafetyService from "@/services/safety-service";
import UserManageForm, {
  PermissionManagementList
} from "@/types/user-manage-form";
import CompanyService from "@/services/company-service";
import { ValidationProvider } from "vee-validate";
import store from "@/store";
import Company from "@/types/company";
import Profile from "@/types/profile";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import PartnerService, { PartnerList } from "@/services/partner-service";
import BorrowerService from "@/services/borrower-service";
import UserRelations from "@/components/users/UserRelations.vue";
import { CommunicationEnum } from "@/types/communication";
import Borrower from "../../types/borrower";
import debounce from "debounce";
import { format as formatCPF } from "@/utils/cpf";
import PermissionAccess from "@/types/permission";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

type SelectItem = {
  value: string | number;
  text: string;
};

interface CompanyWithProfiles extends Company {
  profiles: Profile[];
}

@Component({
  components: { ValidationObserver, ValidationProvider, Modal, UserRelations },
  directives: { mask }
})
export default class UsersManageModal extends Vue {
  @Prop() readonly user?: User | undefined;
  @Prop() readonly defaultPermissionsForAdminGooroo?:
    | PermissionManagementList
    | undefined;
  @Prop() readonly defaultPermissionsForPartnerMaster?:
    | PermissionManagementList
    | undefined;
  @Prop() readonly defaultPermissionsForPartner?:
    | PermissionManagementList
    | undefined;

  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  CommunicationEnum = CommunicationEnum;
  UserFlag = UserFlag;
  UserStatus = UserStatus;
  service: SafetyService;
  companyService: CompanyService;
  partnerService: PartnerService;
  borrowerService: BorrowerService;
  companies: CompanyWithProfiles[];
  partners: PartnerList | null;
  userType: string;
  loading = true;
  loadingUserBorrower = false;
  borrowerSearch: string | null = null;
  loadingBorrowers = false;
  borrowers: Borrower[] = [];
  userTypes: SelectItem[] = [];
  loadingPermissions = false;
  permissionList: PermissionManagementList = [];
  form: UserManageForm = {
    name: "",
    email: "",
    phone: "",
    status: 0,
    flag: 0,
    type: "",
    companiesIds: [],
    permissionsIds: [],
    profilesIds: [],
    activatedCommunications: [
      CommunicationEnum.ENDORSEMENT.name!,
      CommunicationEnum.RECONCILIATION_OR_CHARGES.name!
    ],
    borrowerId: null
  };

  formatCPF = formatCPF;

  constructor() {
    super();
    this.service = SafetyService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.borrowerService = BorrowerService.getInstance();
    this.companies = [];
    this.partners = null;

    if (this.user) {
      this.form = {
        ...this.user,
        phone: this.user.phone.replace(/\+55/g, ""),
        companiesIds: this.user.companies?.map((company) => company.id) || [],
        profilesIds: this.user.profiles?.map((profile) => profile.id) || [],
        permissionsIds: [],
        activatedCommunications: Object.values(CommunicationEnum)
          .filter(
            (c) =>
              !this.user!.blacklistedCommunications.find(
                (bc) => bc.communicationId === c.id
              )
          )
          .map((c) => c.name!)
      };
    }

    this.userType = this.authenticatedUser.type;

    if (this.userType === "ADMIN_GOOROO") {
      this.userTypes.push({
        value: "ADMIN_GOOROO",
        text: "Administrador do sistema"
      });
      this.userTypes.push({
        value: "PARTNER",
        text: "Parceiro"
      });
      if (!this.user) {
        this.form.type = "ADMIN_GOOROO";
      }
    } else if (this.userType === "ADMIN_GROUP") {
      this.userTypes.push({
        value: "EMPLOYEE",
        text: "Funcionário da empresa"
      });
      if (!this.user) {
        this.form.type = "EMPLOYEE";
      }
    } else if (this.userType === "PARTNER_MASTER") {
      this.userTypes.push({
        value: "PARTNER",
        text: "Parceiro"
      });
      if (!this.user) {
        this.form.type = "PARTNER";
      }
    } else if (this.userType === "PARTNER") {
      this.userTypes.push({
        value: "PARTNER",
        text: "Parceiro"
      });
      if (!this.user) {
        this.form.type = "PARTNER";
      }
    }
  }

  async created() {
    if (this.userType === "ADMIN_GROUP") {
      await this.fetchCompanies();
    }

    if (this.form.type === "PARTNER") {
      await this.fetchPartners();
      this.fetchUserPartnerId();
    }

    if (this.form.type === "BORROWER") {
      await this.fetchUserBorrower();
    }

    this.loading = false;
  }

  async mounted() {
    await this.selectPermissionListAndCheckPermissions(this.form.type);
  }

  async selectPermissionListAndCheckPermissions(type: string) {
    if (this.canManageUserPermissions) {
      this.loadingPermissions = true;

      let defaultPermissions: PermissionManagementList | undefined = [];

      if (type === "ADMIN_GOOROO") {
        defaultPermissions = this.defaultPermissionsForAdminGooroo!;
      } else if (type === "PARTNER_MASTER") {
        defaultPermissions = this.defaultPermissionsForPartnerMaster!;
      } else if (type === "PARTNER") {
        defaultPermissions = this.defaultPermissionsForPartner!;
      } else {
        return;
      }

      this.permissionList = defaultPermissions;

      if (this.user) {
        // Mark as checked only permissions that the user already has
        const [error, userPermissions] =
          await this.service.listPermissionsByUserId(this.user.id!);

        if (error) {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
          this.loadingPermissions = false;
          return;
        }

        this.form.permissionsIds = userPermissions!.map((up) => up.id);
      } else {
        /**
         * Mark all permissions as checked, except permissions that require
         * current user to have them, in order to prevent accidental assignment
         */
        this.$nextTick(() => {
          this.form.permissionsIds = this.permissionList.reduce(
            (accumulated, current) =>
              accumulated.concat(
                current.children
                  .filter((p) => !p.canOnlyBeAssignedIfRequestUserHasIt)
                  .map((p) => p.id)
              ),
            [] as number[]
          );
        });
      }

      this.loadingPermissions = false;
    }
  }

  async fetchPartners() {
    if (["PARTNER_MASTER", "PARTNER"].includes(this.userType)) {
      const [error, partner] = await this.partnerService.getLoggedPartner();
      if (!error) {
        this.partners = {
          data: [partner!],
          total: 1
        };
      }
    } else {
      const [error, partners] = await this.partnerService.listPartners({
        limit: 1000000
      });
      if (!error) {
        this.partners = partners;
      }
    }
  }

  async fetchUserPartnerId(): Promise<void> {
    if (!this.user || !this.user.id) {
      return;
    }
    const [error, partnerId] = await this.partnerService.getUserPartner(
      this.user.id
    );

    if (!error && partnerId && partnerId.partnerId) {
      this.form.partnerId = partnerId.partnerId;
      this.form = { ...this.form, partnerId: partnerId.partnerId };
    }
  }

  async fetchUserBorrower(): Promise<void> {
    if (!this.user?.id) {
      return;
    }

    this.loadingUserBorrower = true;

    const [error, borrower] = await this.borrowerService.getBorrowerByUserId(
      this.user.id
    );

    if (!error && borrower) {
      this.borrowers.push(borrower);
      this.form.borrowerId = borrower.id;
    }

    this.loadingUserBorrower = false;
  }

  async fetchBorrowers(): Promise<void> {
    this.loading = true;
    const [error, borrowers] = await this.borrowerService.list({
      borrowerNameOrCpf: this.borrowerSearch!,
      withCompanies: false,
      limit: 10
    });

    if (!error && borrowers) {
      this.borrowers = borrowers.data.map((borrower) => {
        borrower.borrowerNameAndCpf = `${borrower.name} - ${formatCPF(
          borrower.cpf!
        )}`;
        return borrower;
      });
    }

    this.loading = false;
  }

  async save() {
    this.loading = true;
    let error, user;
    const form: UserManageForm & {
      communicationPreferences: Array<{
        communicationName: string;
        activated: boolean;
      }>;
      permissionsNames: string[];
    } = {
      ...this.form,
      communicationPreferences: this.canSetCommunicationPreferences
        ? Object.values(CommunicationEnum).map((c) => ({
            communicationName: c.name!,
            activated: !!this.form.activatedCommunications.find(
              (ac) => ac === c.name
            )
          }))
        : [],
      permissionsNames: this.canManageUserPermissions
        ? this.permissionList
            .reduce(
              (all, modulePermissions) =>
                all.concat(modulePermissions.children),
              [] as Array<
                PermissionAccess & {
                  disabled: boolean;
                }
              >
            )
            .filter((p) => this.form.permissionsIds.includes(p.id))
            .map((p) => p.name)
        : []
    };

    if (this.user) {
      // Update existing User

      [error, user] = await this.service.updateUser(this.user.id!, form);
    } else {
      // Create new User

      if (form.type === "EMPLOYEE") {
        [error, user] = await this.service.signupEmployee(form);
      } else if (form.type === "ADMIN_GROUP") {
        [error, user] = await this.service.signupAdminGroup(form);
      } else if (form.type === "ADMIN_GOOROO") {
        [error, user] = await this.service.signupAdminGooroo(form);
      } else if (form.type === "PARTNER") {
        [error, user] = await this.service.signupPartner(form);
      } else {
        this.$notify({ type: "error", text: "Tipo de usuário não suportado" });
        this.loading = false;
        return;
      }
    }

    if (!error) {
      this.$notify({ type: "success", text: "Usuário salvo com sucesso" });
      this.$emit("input", user);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }

    this.loading = false;
  }

  async fetchCompanies() {
    const [error, companiesData] = await this.companyService.listCompanies({
      page: 1,
      limit: 10000
    });

    if (!error) {
      this.companies = companiesData!.items as unknown as CompanyWithProfiles[];
      const profiles = await this.fetchProfiles();
      this.companies = this.companies.map((company) => {
        company.profiles = profiles.filter(
          (profile) => profile.companyId === company.id
        );
        return company;
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async fetchProfiles(): Promise<Profile[]> {
    const [error, profilesData] = await this.service.listProfiles();
    if (!error) {
      return profilesData;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
      return [];
    }
  }

  @Watch("form.companiesIds")
  companiesIdsChanged(value: Array<number>, oldValue: Array<number>) {
    // When company is unchecked, gets all their profile ids and uncheck
    if (oldValue.length > value.length) {
      const companyId = this.symetricalArraydiff(oldValue, value)[0];
      const company = this.companies.find((company) => company.id == companyId);
      const companyProfileIds =
        company?.profiles.map((profile) => profile.id) || [];

      this.form.profilesIds = this.form.profilesIds.filter(
        (val) => !companyProfileIds.includes(val)
      );
    }
  }

  symetricalArraydiff(first: Array<number>, second: Array<number>) {
    return [
      ...first.filter((x) => !second.includes(x)),
      ...second.filter((x) => !first.includes(x))
    ];
  }

  close() {
    this.$emit("close");
  }

  userTypeChanged(value: string) {
    this.selectPermissionListAndCheckPermissions(value);

    if (value === "PARTNER") {
      if (this.user) {
        this.fetchUserPartnerId();
      }

      if (!this.partners || this.partners.total === 0) {
        this.fetchPartners();
      }
    }
  }

  get canManageUserPermissions() {
    return (
      this.hasPermissions(["GERENCIAR_PERMISSOES_USUARIOS"]) &&
      ((this.isAdminGooroo &&
        ["ADMIN_GOOROO", "PARTNER_MASTER", "PARTNER"].includes(
          this.form.type
        )) ||
        (this.isPartnerMaster && ["PARTNER"].includes(this.form.type)))
    );
  }

  get canSetCommunicationPreferences() {
    return (
      this.isAdminGooroo &&
      (this.form.type === "ADMIN_GROUP" ||
        (this.user && this.user.type === "ADMIN_GROUP"))
    );
  }

  get canUpdateUserRelatedBorrower() {
    return this.isAdminGooroo && this.user && this.form.type === "BORROWER";
  }

  get canSubmit() {
    if (this.form.type !== "EMPLOYEE") {
      return true;
    }
    if (
      this.companies.length &&
      this.form.companiesIds.length &&
      this.form.profilesIds.length
    ) {
      return this.form.companiesIds.every((companyId) => {
        const company = this.companies.find((c) => c.id === companyId);
        if (company && company.profiles && company.profiles.length) {
          return company.profiles.some((profile) =>
            this.form.profilesIds.includes(profile.id)
          );
        }
      });
    }
    return false;
  }

  searchBorrowers = debounce(async () => {
    await this.fetchBorrowers();
  }, 800);

  get formTitle() {
    return this.user ? "Editar usuário" : "Novo usuário";
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get isPartnerMaster(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "PARTNER_MASTER"
    );
  }
}
